import request from '@/until/request.js'
export function cloudDemandUseradd(data) {
    return request({
      url: '/cloud/demandUser/add',
      method: 'post',
      data
    })
}

// export function manageActivityDetail(params) {
//     return request({
//       url: '/manage/activity/detail',
//       method: 'get',
//       params
//     })
//   }

// export function manageActivityDetail() {
//     return request({
//       url: '/selectSchoolInfoList',
//       method: 'get'
//     })
//   }