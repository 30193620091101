import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000 // request timeout
})

service.interceptors.request.use(
  config => { 
    // console.log(process.env.VUE_APP_BASE_API,"统一路径");
    console.log(config,666);
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    console.log(response,'6665454545');
    if (res.code !== '200') {
      return Promise.reject(new Error(res.messages || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log(error.response,'sfsdg') // for debug
    return Promise.reject(error)
  }
)

export default service
