import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    navIndex:0,
    equipment:"",
    scrollTop:true
  },
  getters: {
  },
  mutations: {
    CHANGE_NAV(state,parem){
      state.navIndex = parem
    },
    CHANGE_STATE(state,parem){
      state.equipment = parem
    },
    CHANGE_SOLL(state,parem){
      state.scrollTop = parem
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    // veux持久化配置
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
})
