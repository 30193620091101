import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { imgsPreloader } from './until/imgPreloader';
import imgFistLoader from './until/imgFistLoader.js';
import moimgFistLoader from './until/moimgFistLoader.js';
import "@/assets/text/text.css";//字体包
import "./assets/compont.scss"
import "./assets/acquiesce.css"
const app = createApp(App)
// 全局挂载img前缀路径
app.config.globalProperties.$imgurl = process.env.VUE_APP_IMG_API;
//引入自定义全局状态插件
import MessagePlugin from './until/message';
// 当前设备信息
const isMobile = /Mobile/i.test(window.navigator.userAgent);
import { Swipe, SwipeItem } from "vant";
import 'vant/lib/index.css';
app.use(Swipe);
app.use(SwipeItem);
(async () =>{
    if(isMobile){
        // 手机端预加载
        await imgsPreloader(moimgFistLoader);
    }else{
        // pc端预加载
        await imgsPreloader(imgFistLoader);
    }
    // 关闭加载弹框
    document.querySelector('#Loading').style.display = 'none';
    document.body.removeChild(document.getElementById('Loading'));
    app.use(store).use(router).use(MessagePlugin)
    app.mount('#app')}
)()