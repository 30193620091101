import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import navBar from "@/components/navBar.vue";
import { onMounted } from "vue";
import { useStore } from "vuex";
export default {
  __name: 'App',
  setup(__props) {
    const store = useStore();
    // 全局注册滚动事件
    const handleScroll = () => {
      const {
        scrollTop
      } = document.documentElement; // 获取滚动条位置
      if (scrollTop === 0) {
        store.commit("CHANGE_SOLL", true);
      } else {
        store.commit("CHANGE_SOLL", false);
      }
    };
    onMounted(() => {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobileKeywords = ["windows", "mac"];
      // 设备判断
      if (mobileKeywords.some(keyword => userAgent.includes(keyword))) {
        store.commit("CHANGE_STATE", "pc");
        if (window.innerWidth >= 751) {
          store.commit("CHANGE_STATE", "pc");
        } else {
          store.commit("CHANGE_STATE", "mo");
          let all = document.documentElement;
          all.style.fontSize = window.innerWidth / 375 * 16 + "px";
        }
        //注册侦听事件改变rem
        window.addEventListener("resize", () => {
          if (window.innerWidth >= 751) {
            store.commit("CHANGE_STATE", "pc");
          } else {
            store.commit("CHANGE_STATE", "mo");
            let all = document.documentElement;
            all.style.fontSize = window.innerWidth / 375 * 16 + "px";
          }
        });
      } else {
        //初始化当前rem
        let all = document.documentElement;
        all.style.fontSize = window.innerWidth / 375 * 16 + "px";
        store.commit("CHANGE_STATE", "mo");
      }
      window.addEventListener("scroll", handleScroll); // 监听滚动事件
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(navBar), _createVNode(_component_router_view)], 64);
    };
  }
};