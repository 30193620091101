// 首次加载
const apiurl = process.env.VUE_APP_IMG_API
export default [
  apiurl+  "/moImg/home/banner.png", 
  apiurl+  "/moImg/case/banner.png", 
  apiurl+  "/moImg/advantage/bannner.png", 
  apiurl+  "/moImg/about/banner.png", 
  apiurl+  "/moImg/about/lastbanner.png",
  apiurl+"/homeImg/swipero1.png",
  apiurl+"/homeImg/swipero2.png",
  apiurl+"/homeImg/swipero3.png",
  ];