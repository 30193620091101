import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5e4f1b5a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "message-warp"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message", $props.type])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["message-warp-logo", $props.type])
  }, null, 2), _createElementVNode("div", {
    class: _normalizeClass(["message-warp-span", $props.type])
  }, _toDisplayString($props.message), 3)])], 2);
}