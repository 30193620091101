import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src"];
import { ref, getCurrentInstance } from "vue";
import { computed } from "vue";
export default {
  __name: 'mine',
  props: ["type"],
  setup(__props) {
    const state = ref(2);
    const props = __props;
    const {
      proxy
    } = getCurrentInstance();

    // 计算属性侦听换图
    const changeImg = computed(() => {
      if (props.type) {
        if (state.value == 1) {
          return proxy.$imgurl + "/commontImg/mine2.png";
        } else if (state.value == 2) {
          return proxy.$imgurl + "/commontImg/mine1.png";
        } else if (state.value == 3) {
          return proxy.$imgurl + "/commontImg/mine3.png";
        }
      } else {
        return proxy.$imgurl + "/commontImg/mine4.png";
      }
    });
    // 鼠標事件
    function mouse(data) {
      if (state == 4) {
        return false;
      }
      state.value = data;
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "more",
        onMouseenter: _cache[0] || (_cache[0] = $event => mouse(1)),
        onMouseleave: _cache[1] || (_cache[1] = $event => mouse(2)),
        onMousedown: _cache[2] || (_cache[2] = $event => mouse(3)),
        onMouseup: _cache[3] || (_cache[3] = $event => mouse(1))
      }, [_createElementVNode("img", {
        src: changeImg.value,
        alt: "",
        srcset: ""
      }, null, 8, _hoisted_1)], 32);
    };
  }
};