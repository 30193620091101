import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue')
  },{
    path: '/case',
    name: 'case',
    component: () => import(/* webpackChunkName: "about" */ '../views/case/index.vue')
  },{
    path: '/advantage',
    name: 'advantage',
    component: () => import(/* webpackChunkName: "about" */ '../views/advantage/index.vue')
  },{
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
