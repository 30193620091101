import { createApp, h } from 'vue';
import Message from '../components/message.vue';

const showMessage = (message, type = 'info') => {
  const messageContainer = document.createElement('div');
  const app = createApp({
    render() {
      return h(Message, {
        message: message,
        type: type,
      });
    },
  });

  const messageInstance = app.mount(messageContainer);
  document.body.appendChild(messageContainer);

  setTimeout(() => {
    messageInstance.$el.classList.add('show');
    setTimeout(() => {
      messageInstance.$el.classList.remove('show');
      setTimeout(() => {
        app.unmount();
        document.body.removeChild(messageContainer);
      }, 300);
    }, 3000);
  }, 0);
};

export default {
  install(app) {
    app.config.globalProperties.$showMessage = showMessage;
  },
};
